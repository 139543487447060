import { combineReducers } from 'redux';

const lodash = require('lodash');

const data_type = {
  index : "",
  name : "",
  love : 3,
  hate : 2,
  score : 0,
  lock : 1,
};

const data_type_record = [
{
  lock : false,
  from_love : [],
  from_hate : []
},
{
  lock : false,
  from_love : [],
  from_hate : []
},
{
  lock : false,
  from_love : [],
  from_hate : []
},
{
  lock : false,
  from_love : [],
  from_hate : []
},
{
  lock : false,
  from_love : [],
  from_hate : []
},
]

const setdata = (idx, name) => {
  const record_init = JSON.parse(JSON.stringify(data_type_record))
  const tmp_data = {...data_type, record : record_init};
  tmp_data.index = idx;
  tmp_data.name = name;
  return tmp_data;
}

const initdata = [];

const user = (state = initdata, action) => {
  switch (action.type) {
    case "RESET" :
      return [];
    // 처음 사용자 등록
    case "SET_LOCKER" :
      return [...state, {...setdata(action.idx, action.name)}];
    // 편지 보냈을 때
    case "GET_LETTER" :
      // letter_type : letter, from : selectItem.index, to:letterto, round
      const type = action.letter_type;
      const from_data = {...state[(action.from).charCodeAt(0) - 65]} //보낸사람 데이터
      const to_data = {...state[(action.to).charCodeAt(0) - 65]} //받을사람 데이터
      const tmp_data = [...state];
      // selectItem의 letter 갯수 줄이기.
      from_data[type] -= 1;
      tmp_data.splice((action.from).charCodeAt(0) - 65, 1, from_data);

      if(!to_data.record[action.round - 1].lock) {
        
        // letterto의 점수 세팅 + record 기록
        if(type === 'love') {
          to_data.score += 1;
        } else if (type === 'hate') {
          to_data.score -= 1;
        }

        const tmp_record = {...to_data.record[action.round - 1]};
  
        if (type === 'love') {
          tmp_record.from_love.push(from_data.index);
        }
        else if(type === 'hate'){
          tmp_record.from_hate.push(from_data.index);
        }
        to_data.record.splice(action.round-1, 1, tmp_record);
      }

      tmp_data.splice((action.to).charCodeAt(0) - 65, 1, to_data);
      return [...tmp_data];
    case "GUESS_RIGHT" :
      const tmp_score = lodash(state);
      const guess_to_data = tmp_score[(action.to).charCodeAt(0) - 65] // 맞추는 사람 데이터
      const guess_from_data = tmp_score[(action.answer).charCodeAt(0) - 65] // 편지 보낸 사람 데이터
      if(action.letter_type === 'love') {
        // 받은사람(=맞춘사람) +1
        guess_to_data.score += 1;
      } else if(action.letter_type === 'hate') {
        // 받은사람(=맞춘사람) 0 / 보낸사람 -2
        guess_from_data.score -= 2;
      }
      tmp_score.splice((action.to).charCodeAt(0) - 65, 1, guess_to_data);
      tmp_score.splice((action.answer).charCodeAt(0) - 65, 1, guess_from_data);
      return [...tmp_score];
    case "GUESS_WRONG" :
      const tmp_score2 = [...state];
      const guess_to_data_wr = {...state[(action.to).charCodeAt(0) - 65]} // 맞추는 사람 데이터
      // 보낸사람에게 받은사람 이름 공개

      if(action.letter_type === 'hate') {
        // 받은사람 -1
        guess_to_data_wr.score -= 1;
      } 
      tmp_score2.splice((action.to).charCodeAt(0) - 65, 1, guess_to_data_wr);
      return [...tmp_score2];
    case "USE_LOCK":
      const lock_user = action.user;
      const tmp_lock = [...state];
      const tmp_lock_user = {...state[(lock_user).charCodeAt(0) - 65]};
      const origin_love = tmp_lock_user.record[action.round - 1].from_love;
      const origin_hate = tmp_lock_user.record[action.round - 1].from_hate;
      tmp_lock_user.score = tmp_lock_user.score + origin_hate.length - origin_love.length;
      tmp_lock_user.record[action.round - 1] = {lock : true, from_love : [], from_hate : [] }
      tmp_lock_user.lock -= 1;
      tmp_lock.splice((lock_user).charCodeAt(0) - 65, 1, tmp_lock_user);
      return [...tmp_lock];
    case "SET_SCORE" :
      const score_data = [...state];
      const win_idx = action.win;
      const lose_idx = action.lose;
      if(win_idx > -1) {
        const win_data = {...state[win_idx]};
        win_data.score += 1;
        score_data.splice(win_idx, 1, win_data);
      }

      const lose_data = {...state[lose_idx]};
      lose_data.score -= 1;
      score_data.splice(lose_idx, 1, lose_data);
      return [...score_data];
    default:
      return state;
  }
}

const record = (state = [], action) => {
  switch(action.type) {
    case "RESET_RECORD" :
      return [];
    case "SET_RECORD" :
      if (state.length < action.round) {
        // 배열 만들어서 추가
        return [...state, [{from : action.from, to : action.to, letter : action.letter_type}]]
      } else {
        // 마지막 배열에 데이터추가
        const tmp_list = [...state];
        const tmp_list2 = [...tmp_list[action.round - 1], {from : action.from, to : action.to, letter : action.letter_type}];
        tmp_list.splice(action.round - 1, 1, tmp_list2);
        return [...tmp_list];
      }
    default :
      return state;
  }
}

const rootReducer = combineReducers({
  user,
  record
})

export default rootReducer;