import React, { useState } from "react";
import { styled } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LockerInput } from "../component";

const Locker = () => {
  const data = useSelector(state => state.user);
  const navigate = useNavigate();
  const [inputs, setInputs] = useState([]);
  const dispatch = useDispatch();

  let answer = {}
  for (let i = 0; i <data.length; i++) {
    const tmp = data[i].index;
    const tmp2 = data[i].name;
    answer = {...answer, [tmp] : tmp2};
  }
  const check = (input, index) => {
    const tmp = [...inputs];
    tmp.splice(index.charCodeAt(0) - 65, 1, {...input})
    setInputs([...tmp]);
  }

  const getScore = () => {
    for ( let i = 0;i<inputs.length; i++) {
      const user_answer = inputs[i];
      const answer_keys = Object.keys(answer);

      for (let key of answer_keys) {
        if (key.charCodeAt(0) - 65 === i) {
          continue;
        }
        if( user_answer[key] === answer[key]) {
          // i의 점수 +1 / key의 점수 -1
          dispatch({type:"SET_SCORE", win : i, lose : key.charCodeAt(0) - 65}) //i는 숫자, key는 문자
        } else if (user_answer[key] !== "" && user_answer[key] !== answer[key]) {
          // i의 점수 -1
          dispatch({type:"SET_SCORE", win : -1, lose : i}) //i는 숫자, key는 문자
        }
      }
    }

    navigate('/result/2')
  }

  return (
    <>
      <header>사물함 맞추기</header>
      {data.map((item, idx) => (
        <LockerInput item={item} key={idx} setData={check}/>
      ))}
      <button onClick={() => {getScore();}}>최종 결과</button>
    </>
  )
}

export default Locker;