import React, { useState } from "react";
import { styled } from "styled-components";
import {SelectSection} from './index';
import { useSelector } from "react-redux";

const S = {
  Body : styled.div`
    width : 80vm;
    display : flex;
  `,
  Header : styled.div`
    width : 20%;
  `,
  Menu : styled.div`
  `,
  MenuItem : styled.div`
  `,
  Section : styled.div`
    width : 80%;
  `
}

const Select = ({data, round}) => {
  const [selectname, setSelectname] = useState(data[0].name);

  return (
    <S.Body>
      <S.Header>
        <S.Menu>
          {data.map((item, idx) => {
            return (
              <S.MenuItem key={idx} onClick={() => setSelectname(item.name)}>{item.index} {item.name}</S.MenuItem>
            )
          })}
        </S.Menu>
      </S.Header>
      <S.Section>
        {selectname}
        {data.map((item, idx) => (
          <div key={idx} style={{'display' : selectname === item.name ? '' : 'none'}}>
            <SelectSection round={round} selectItem={item}/>
          </div>
        ))}
      </S.Section>
    </S.Body>
  )
}

export default Select;