import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {styled} from 'styled-components';
import {GuessSection} from '../component';
import { useLocation, useParams } from "react-router-dom";

const S = {
  Body : styled.div`
    border : 1px solid black;
  `,
  GetLetter : styled.div`
    display :flex;
  `,
  Guess : styled.div`
    display : flex;
  `,
  GuessSection : styled.div`
    flex-direction : row;
  `,
  PostLetter : styled.div`
    display :flex;
  `,
  InputButton : styled.div`
    width : 100px;
    border : 1px solid black;
    margin : 0 20px;
  `,
  InputName : styled.select`
    width : 100px;
  `,
  LeftLetter : styled.div`
    display : flex;
  `,
  Lock : styled.div`
    display : flex;
  `
}

const SelectSection = ({round, selectItem}) => {
  const [letter, setLetter] = useState("");
  const [guessletter, setGuessletter] = useState("");
  const data = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [result, setResult] = useState("");
  const [sendstate, setSendstate] = useState("false");
  const [lovecnt, setLovecnt] = useState(0);
  const [hatecnt, setHatecnt] = useState(0);

  const url = useParams().id;

  useEffect(() => {
    setLetter("")
    setSendstate("")
  }, [url])
  
  const sendletter = () => {
    const getall = document.querySelectorAll('.sendletter');
    const letterto = getall[(selectItem.index).charCodeAt(0) - 65].value;
    if(letter === "") {
      alert("편지를 선택해 주세요")
    } else if(selectItem.love + selectItem.hate > 5 - round) {
      if (letterto === selectItem.index) {
        alert("자신에겐 편지를 보낼 수 없습니다.")
        return
      }
      //selectItem.index가 letterto에게 보냄.
      dispatch({type : 'GET_LETTER', letter_type : letter, from : selectItem.index, to:letterto, round})
      dispatch({type : 'SET_RECORD', letter_type : letter, from : selectItem.index, to:letterto, round})
      setSendstate("true");
    } else {
      alert("더이상 편지를 보낼 수 없습니다.")
    }
  }
  

  const lettercount = () => {
    const preround = selectItem.record[round - 2];
    setLovecnt(preround.from_love.length);
    setHatecnt(preround.from_hate.length);

  }

  const locking = () => {
    if(window.confirm("사용하시겠습니까?") === true) {
      dispatch({type:'USE_LOCK', user : selectItem.index, round})
      alert("자물쇠를 사용하였습니다.");
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (round > 1) {
      lettercount();
      setLetter("");
      setGuessletter("");
      setResult('');
    }
  }, [selectItem, round])


  return (
    <S.Body>
      { round > 1 &&
          <>
            <S.GetLetter>
              <div>받은 편지</div>
              ♥ {lovecnt}개, X : {hatecnt}개
            </S.GetLetter>
            <S.Guess>
              <div>추측하기</div>
              <S.GuessSection>
                {Array.from({length : lovecnt + hatecnt}, (x, index) => (
                  <GuessSection selectItem={selectItem} round={round} idx={index} name={selectItem.index}/>
                ))}
              </S.GuessSection>
            </S.Guess>
          </>
      }
      <S.LeftLetter>
        <div>남은 편지</div>
        ♥ {selectItem.love}개, X : {selectItem.hate}개
      </S.LeftLetter>
      <S.PostLetter>
        <div>보낼 편지</div>
        <S.InputButton onClick={() => setLetter("love")} style={{backgroundColor : letter === 'love' ? 'yellow' : ''}}>♥</S.InputButton>
        <S.InputButton onClick={() => setLetter("hate")} style={{backgroundColor : letter === 'hate' ? 'yellow' : ''}}>X</S.InputButton>
        <S.InputName className={"sendletter"}>
          {data.map((item, idx) => (
            <option key={idx} value={item.index}>{item.index}</option>
          ))}
        </S.InputName>
        <button onClick={sendletter}>보내기</button>
        <div>{sendstate}</div>
      </S.PostLetter>
      {selectItem.lock > 0 &&
        <S.Lock>
          <div>좌물쇠</div>
          <button className={`check${selectItem.index}`} onClick={() => locking()}>잠그기</button>
        </S.Lock>
      }
    </S.Body>
  )
}

export default SelectSection;
