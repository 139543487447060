import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const [open, setOpen] = useState(false);
  const [person, setPerson] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const setperson = (e) => {
    setPerson(e.target.value);
  }

  const setlocker = () => {
    setOpen(true);
  }

  const submit = () => {
    dispatch({type:'RESET'});
    dispatch({type:'RESET_RECORD'});
    for (let i = 0; i < person ; i++) {
      const name = inputRefs.current[i].value;
      dispatch({type:'SET_LOCKER', idx : String.fromCharCode(i+65), name});
    }

    navigate('/round/1');

  }

  return (
    <>
      <header> 설정하기 </header>
      <div>인원수</div>
      <input type="number" id='person' value={person} onChange={setperson}/>

      <button onClick={setlocker}>다음</button>

      <div id='locker' style={{display : open ? '' : 'none'}}>
        <div>각 사물함의 주인을 입력해주세요.</div>
        {Array.from({length : person},(_,index)=> 
          <div key={index}>
            {String.fromCharCode(index+65)}
            <input ref={el => inputRefs.current[index] = el}/>
          </div>
        )}

        <button onClick={submit}>완료</button>
      </div>
    </>
  )
}

export default Setting;