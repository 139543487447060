import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from '../component';

const Round = () => {
  const round = useParams().id;
  const data = useSelector(state => state.user);
  const navigate = useNavigate();

  return (
    <>
      <header>{round} 라운드</header>
      <Select data={data} round={round}/>
      {round <= 4 ?
        <button onClick={() => navigate(`/round/${Number(round)+1}`)}>다음 라운드</button>
        :
        <button onClick={() => navigate(`/result/1`)}>중간 결과 보기</button>
      }
    </>
  )
}

export default Round;