import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {styled } from 'styled-components';

const S = {
  Body : styled.div`
    border : 1px solid black;
  `,
  GetLetter : styled.div`
    display :flex;
  `,
  Guess : styled.div`
    display : flex;
  `,
  PostLetter : styled.div`
    display :flex;
  `,
  InputButton : styled.div`
    width : 100px;
    border : 1px solid black;
    margin : 0 20px;
  `,
  InputName : styled.select`
    width : 100px;
  `,
  LeftLetter : styled.div`
    display : flex;
  `,
  Lock : styled.div`
    display : flex;
  `
}

const GuessSection = ({round, selectItem, idx, name}) => {

  const [guessletter, setGuessletter] = useState("");
  const [result, setResult] = useState("");
  const data = useSelector(state => state.user);
  const dispatch = useDispatch();

  const url = useParams().id;

  useEffect(() => {
    setResult("")
  }, [url])

  const guess = () => {
    const getguessall = document.querySelectorAll('.guessletter' + name);
    const guessmem = getguessall[idx].value;
    
    const getlove = selectItem.record[round - 2].from_love;
    const gethate = selectItem.record[round - 2].from_hate;

    if (guessletter === 'love') {
      // to가 편지 받아서 맞춰야하는 사람
      if (getlove.includes(guessmem)) {
        setResult("true");
        dispatch({type : 'GUESS_RIGHT', letter_type : guessletter, to : selectItem.index, answer : guessmem})
      }
      else {
        setResult("false");
        dispatch({type : 'GUESS_WRONG', letter_type : guessletter, to : selectItem.index})
      }
    }
    else if (guessletter === 'hate') {
      if (gethate.includes(guessmem)) {
        setResult("true");
        dispatch({type : 'GUESS_RIGHT', letter_type : guessletter, to : selectItem.index, answer : guessmem})
      } else {
        setResult("false");
        dispatch({type : 'GUESS_WRONG', letter_type : guessletter, to : selectItem.index})
      }
    }
  }

  return (
    <>
    <div style={{'display' : 'flex'}}>
      <S.InputButton onClick={() => setGuessletter("love")} style={{backgroundColor : (result === 'true' && guessletter === 'love') ? 'blue' : (guessletter === 'love' && result === 'false') ? 'red' : guessletter === 'love' ? 'yellow' :''}}>♥</S.InputButton>
      <S.InputButton onClick={() => setGuessletter("hate")} style={{backgroundColor : (result === 'true' && guessletter === 'love') ? 'blue' : (guessletter === 'hate' && result === 'false') ? 'red' : guessletter === 'hate' ? 'yellow' :''}}>X</S.InputButton>
      <S.InputName className={'guessletter' + name}>
        {data.map((item, idx) => (
          <option key={idx} value={item.index}>{item.name}</option>
        ))}
      </S.InputName>
      <button onClick={guess}>추측 결과 보기</button>
      <div>{result}</div>
    </div>
    </>
  )
}

export default GuessSection;