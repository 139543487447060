import React from "react";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();

  return (
    <>
      <header>LOVE LETTER</header>

      <button onClick={() => navigate('/setting')}>
        시작하기
      </button>
    </>
  )
}

export default Main;