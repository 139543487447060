import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from './rootReducer';
import {thunk} from "redux-thunk";
import storage from "redux-persist/lib/storage/session";
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key : 'root',
  storage : storage,
  whitelist : ["user", "record"],
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);
