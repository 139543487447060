import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";

const LockerInput = (props) => {
  const data = useSelector(state => state.user);
  const [score, setScore] = useState(0);

  const obj = () => {
    const res = {}
    for (let i = 0 ; i < data.length ; i ++) {
      const id = String.fromCharCode(i+65);
      res.id = "";
    }
    return res;
  }

  const [inputs, setInputs] = useState({...obj})

  const changeInput = (e, name) => {
    const {id, value} = e.target;
    setInputs({...inputs, [name] : value});
  }

  useEffect(() => {
    props.setData(inputs, props.item.index);
  }, [inputs])
  
  

  return (
    <div>
      {props.item.name}
      {data.map((item2, idx2) => (
        <div key={idx2}>
          {item2.index}
          <input onChange={(e) => changeInput(e, item2.index)}/>
        </div>
      ))}
      <div>점수 : {JSON.stringify(inputs)}</div>
    </div>
  )
}

export default LockerInput;