import React from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Main, Setting, Round, Result, Locker } from './pages'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path='/' element={<Main/>}/>
          <Route path='/setting' element={<Setting/>}/>
          <Route path='/round/:id' element={<Round/>}/>
          <Route path='/result/:id' element={<Result/>}/>
          <Route path='/locker' element={<Locker/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
