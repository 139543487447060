import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const Result = () => {
  const data = useSelector(state => state.user);
  const id = useParams().id;
  const navigate = useNavigate();
  return (
    <div>
    {id === '1' &&
      <div>
        {data.map((item, idx) => (
          <div key={idx}>
            {item.index} : {item.score}점
          </div>
        ))}
        <button onClick={() => navigate('/locker')}>사물함 맞추기</button>
      </div>
    }
    {id === '2' &&
      <div>
        {data.map((item, idx) => (
          <div key={idx}>
            {item.name}({item.index}) : {item.score}점
          </div>
        ))}
      </div>
    }
    </div>
  )
}

export default Result;